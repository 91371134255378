import http from './http'

class Admin {
  systemData() {
    return http.get('/admin/system/data', {
      headers: { accept: 'application/json' },
    })
  }
  manualImports() {
    return http.get('/recebiveis/importacoes_manuais', {
      headers: { accept: 'application/json' },
    })
  }
  importTrademastersData() {
    return http.post(`/admin/system/importar_remessas_trademasters`)
  }
  exigeRegistro() {
    return http.get('/admin/system/exige_registro', {
      headers: { accept: 'application/json' },
    })
  }
}

export default new Admin()
