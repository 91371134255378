export default [
  {
    path: '/admin/concessoes',
    name: 'remessas.index',
    component: () => import('@/views/admin/concessions/pages/Index.vue'),
    meta: {
      action: 'admin-read',
      resource: 'admin-user',
      // pageTitle: 'Gestão de remessas',
      skipGoToHome: true,
      indexObject: {
        datatableTitle: 'Remessas disponíveis',
        serviceUrl: '/recebiveis/remessas',
        editRouteName: 'remessas.show',
      },
    },
  },
  {
    path: '/admin/redescontos',
    name: 'redescontos.index',
    component: () => import('@/views/admin/rediscount/pages/Index.vue'),
    meta: {
      action: 'admin-read',
      resource: 'admin-user',
      skipGoToHome: true,
      indexObject: {
        datatableTitle: 'Redescontos',
        serviceUrl: '/recebiveis/cashu/redesconto',
        editRouteName: 'redescontos.show',
      },
    },
  },
  {
    path: '/admin/redescontos/:id',
    name: 'redescontos.show',
    component: () => import('@/views/admin/rediscount/pages/Show.vue'),
    meta: {
      action: 'admin-read',
      resource: 'admin-user',
    },
  },
  {
    path: '/admin/concessoes/:id',
    name: 'remessas.show',
    component: () => import('@/views/admin/concessions/pages/Show.vue'),
    meta: {
      action: 'admin-read',
      resource: 'admin-user',
      navActiveLink: 'remessas.index',
    },
  },
  {
    path: '/admin/caixa',
    name: 'cashier.index',
    component: () => import('@/views/admin/cashier/pages/Index.vue'),
    meta: {
      action: 'admin-read',
      resource: 'admin-user',
      indexObject: {
        serviceUrl: '/concessao/admin/operacoes',
        editRouteName: 'remessas.show',
      },
    },
  },
  {
    path: '/admin/titulos',
    name: 'titulos.index',
    component: () => import('@/views/admin/titulos/pages/Index.vue'),
    meta: {
      action: 'admin-read',
      resource: 'admin-user',
      pageTitle: 'Gestão de títulos',
      skipGoToHome: true,
      indexObject: {
        datatableTitle: 'Títulos',
        serviceUrl: '/recebiveis/titulos',
        editRouteName: 'titulos.show',
      },
    },
  },
  {
    path: '/admin/extrato',
    name: 'extract.index',
    component: () => import('@/views/admin/extract/pages/Index.vue'),
    meta: {
      action: 'admin-read',
      resource: 'admin-user',
      pageTitle: 'Gestão de ocorrências',
      skipGoToHome: true,
    },
  },
  {
    path: '/admin/operacoes/criar',
    name: 'emissions.new',
    component: () => import('@/views/admin/emissions/pages/New.vue'),
    meta: {
      action: 'admin-read',
      resource: 'admin-user',
    },
  },
  {
    path: '/admin/operacoes/:id/editar',
    name: 'emissions.edit',
    component: () => import('@/views/admin/emissions/pages/Edit.vue'),
    meta: {
      action: 'admin-read',
      resource: 'admin-user',
    },
  },
  {
    path: '/admin/operacoes',
    name: 'emissions.index',
    component: () => import('@/views/admin/emissions/pages/Index.vue'),
    meta: {
      pageTitle: 'Operações cadastradas',
      action: 'admin-read',
      resource: 'admin-user',
      indexObject: {
        datatableTitle: 'Últimas operações cadastradas',
        showRouteName: 'emissions.edit',
      },
    },
  },
  {
    path: '/admin/usuarios',
    name: 'users.index',
    component: () => import('@/views/admin/users/pages/Index.vue'),
    meta: {
      pageTitle: 'Usuários',
      action: 'admin-read',
      resource: 'admin-user',
      skipGoToHome: true,
    },
  },
  {
    path: '/admin/arquivosderetorno',
    name: 'returnfiles.index',
    component: () => import('@/views/admin/return_files/pages/Index.vue'),
    meta: {
      pageTitle: 'Arquivos de retorno',
      action: 'admin-read',
      resource: 'admin-user',
      skipGoToHome: true,
      actions: [
        {
          title: 'Subir novo arquivo de retorno',
          event: 'new_return_file',
          variant: 'light-warning',
          class: 'bg-light-warning border-warning',
        },
      ],
    },
  },
  {
    path: '/admin/arquivosderetorno/:id/detalhes',
    name: 'returnfiles.details',
    component: () => import('@/views/admin/return_files/pages/Details.vue'),
    meta: {
      pageTitle: 'Detalhes do arquivo de retorno',
      action: 'admin-read',
      resource: 'admin-user',
      skipGoToHome: true,
      actions: [
        {
          title: 'Voltar',
          routeName: 'returnfiles.index',
          icon: 'ArrowLeftIcon',
          variant: 'light-warning',
          class: 'bg-white border-warning',
        },
      ],
    },
  },
  {
    path: '/admin/arquivosexcecao',
    name: 'exceptionfiles.index',
    component: () => import('@/views/admin/exception_files/pages/Index.vue'),
    meta: {
      pageTitle: 'Arquivos de exceção',
      action: 'admin-read',
      resource: 'admin-user',
      skipGoToHome: true,
      actions: [
        {
          title: 'Subir novo arquivo de exceção',
          event: 'new_exception_file',
          variant: 'light-warning',
          class: 'bg-light-warning border-warning',
        },
      ],
    },
  },
  {
    path: '/admin/arquivosexcecao/:id/detalhes',
    name: 'exceptionfiles.details',
    component: () => import('@/views/admin/exception_files/pages/Details.vue'),
    meta: {
      pageTitle: 'Detalhes do arquivo de exceção',
      action: 'admin-read',
      resource: 'admin-user',
      skipGoToHome: true,
      actions: [
        {
          title: 'Voltar',
          routeName: 'exceptionfiles.index',
          icon: 'ArrowLeftIcon',
          variant: 'light-warning',
          class: 'bg-white border-warning',
        },
      ],
    },
  },
  {
    path: '/admin/relatorios',
    name: 'reports.index',
    component: () => import('@/views/admin/reports/pages/Index.vue'),
    meta: {
      pageTitle: 'Relatórios',
      action: 'admin-read',
      resource: 'admin-user',
      skipGoToHome: true
    },
  },
  {
    path: '/admin/lastros',
    name: 'lastros.index',
    component: () => import('@/views/admin/lastros/pages/Index.vue'),
    meta: {
      pageTitle: 'Baixar lastros',
      action: 'admin-read',
      resource: 'admin-user',
      skipGoToHome: true
    },
  },
  {
    path: '/admin/swap',
    name: 'swap.index',
    component: () => import('@/views/admin/swap/pages/Index.vue'),
    meta: {
      // pageTitle: 'Swaps',
      action: 'admin-read',
      resource: 'admin-user',
      skipGoToHome: true,
      indexObject: {
        serviceUrl: '/admin/swaps',
        editRouteName: 'swap.edit',
      },
    },
  },
  {
    path: '/admin/swap/new',
    name: 'swap.create',
    component: () => import('@/views/admin/swap/pages/Show.vue'),
    meta: {
      pageTitle: 'Cadastrar Swap',
      action: 'admin-read',
      resource: 'admin-user',
      skipGoToHome: true,
    },
  },
  {
    path: '/admin/swap/edit/:id',
    name: 'swap.edit',
    component: () => import('@/views/admin/swap/pages/Show.vue'),
    meta: {
      pageTitle: 'Editar Swap',
      action: 'admin-read',
      resource: 'admin-user',
      skipGoToHome: true,
    },
  },
  {
    path: '/admin/lastros',
    name: 'lastros.index',
    component: () => import('@/views/admin/lastros/pages/Index.vue'),
    meta: {
      pageTitle: 'Baixar lastros',
      action: 'admin-read',
      resource: 'admin-user',
      skipGoToHome: true
    },
  },
  {
    path: '/admin/cadastro',
    name: 'admin.register',
    component: () => import('@/views/admin/register/pages/Index.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      layoutPage: {
        formCard: {
          title: 'Administrador',
        }
      },
    },
  },
]
